import React from 'react'
import { Box } from 'reflexbox'
import Container from '../components/Container'
import SEO from '../components/SEO'
import Badge from '../components/Badge'

const TermsOfUse = props => {
  return (
    <>
      <SEO title="Acknowledgements" />

      {/* Hero */}
      <Container my={[4, 5]}>
        <Box textAlign={'center'}>
          <h1>Acknowledgements</h1>
          <p className="subtitle">
            IconJar uses the following third party open-source projects/code:
          </p>
        </Box>
      </Container>

      {/* Content */}
      <Container mb={[4, 5]}>
        <Box mx="auto" width={[1, 3 / 5]}>
          <Box mb={[4, 5]}>
            <h4>IJSVG – by Curtis Hard</h4>
            <p>
              IJSVG is an open-source SVG rendering and exporting framework
              written in Object-C/C. More info:{' '}
              <a href="https://github.com/curthard89/IJSVG" rel="nofollow">
                https://github.com/curthard89/IJSVG
              </a>
            </p>
          </Box>
          <Box mb={[4, 5]}>
            <h4>WebP – by Google</h4>
            <p>
              WebP is Googles image encoding and decoding framework for
              compression images. More info:{' '}
              <a
                href="https://developers.google.com/speed/webp/download"
                rel="nofollow">
                https://developers.google.com/speed/webp/download
              </a>
            </p>
          </Box>
          <Box mb={[4, 5]}>
            <h4>Sparkle – by Andy Matuschak</h4>
            <p>
              Sparkle is an easy-to-use software update framework for macOS.
              More info:{' '}
              <a href="https://sparkle-project.org/" rel="nofollow">
                https://sparkle-project.org/
              </a>
            </p>
          </Box>
          
          <Box mb={[4, 5]}>
          <h4>Material Design Icons - by Google</h4>
          <p>
            We ship IconJar with support for Google's Material Design icons.{' '}
            <a href="https://github.com/google/material-design-icons" rel="nofollow">
              https://github.com/google/material-design-icons
            </a>
          </p>
        </Box>

          <Box mb={[4, 5]}>
            <h4>Paddle Mac SDK</h4>
            <p>
              Paddle is a development and distribution library for macOS. More
              info:{' '}
              <a href="https://github.com/DevMate/DevMateKit">
                https://github.com/DevMate/DevMateKit
              </a>
            </p>
          </Box>
          <Box mb={[4, 5]}>
            <h4>
              ITSwitch – by Ilija Tovilo <Badge>V1 specific</Badge>
            </h4>
            <p>
              ITSwitch is an Object-C project that creates iOS style
              switches/checkboxes for macOS projects. More info:{' '}
              <a href="https://github.com/iluuu1994/ITSwitch" rel="nofollow">
                https://github.com/iluuu1994/ITSwitch
              </a>
            </p>
          </Box>
          <Box mb={[4, 5]}>
            <h4>
              DevMateKit – by MacPaw <Badge>V1 specific</Badge>
            </h4>
            <p>
              DevMateKit is a development and distribution library for macOS.
              More info:{' '}
              <a href="https://github.com/DevMate/DevMateKit" rel="nofollow">
                https://github.com/DevMate/DevMateKit
              </a>
            </p>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default TermsOfUse
